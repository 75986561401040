import React from 'react';
import MainComponent from './components/MainComponent/MainComponent'
import Footer from './components/Footer/Footer'
import SecondFooter from './components/Footer/SecondFooter'

const App = () => {
  return (
    <div>
      <MainComponent />
      <Footer />
      <SecondFooter />
    </div>
  );
};
export default App;