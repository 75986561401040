import React from 'react'
import {
    AppBar,
    Container,
    Typography,
    Link,
    Box
} from '@mui/material';
import classObj from './Footer.module.css'

export default function Footer() {
    return (
        <Box>
            <AppBar position="static" className={classObj.main}>
                <Container maxWidth="md" className={classObj.container}>
                    <Typography variant="h5" className={classObj.heading}>
                        Looking for something else?
                    </Typography>
                    <Typography className={classObj.text}>
                        We're always here to help. Please email any questions or concerns to <Link target="_blank" rel="noreferrer" href="mailto:contact@appliedaiconsulting.com" style={{ color: "white" }}>support@appliedaiconsulting.com</Link>
                    </Typography>
                </Container>
            </AppBar>
        </Box>
    )
}