import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const Checkboxmark = (props) => {
    const { item } = props;
    return (
        <>
            {item.score.map((x, i) => {
                return (
                    <div key={i}>
                        {item.measure === "EXCLAMATION MARK" ? (<CheckBoxIcon color={x.value === "found" ? "error" : "success"} />)
                            : item.measure === "SPELLING ERRORS" ? (<CheckBoxIcon color={x.value === "found" ? "error" : "success"} />)
                                : item.measure === "PUNCTUATION" ? (<CheckBoxIcon color={x.value === "found" ? "error" : "success"} />)
                                    : item.measure === 'RE: OR FWD' ? (<CheckBoxIcon color={x.value === "found" ? "error" : "success"} />)
                                        : item.measure === "FREE" ? (<CheckBoxIcon color={x.value === "found" ? "error" : "success"} />)
                                            : item.measure === "PERSONALIZATION" ? (<CheckBoxIcon color={x.value === "found" ? "success" : "grey"} />)
                                                : item.measure === "ALL CAPS WORDS" ? (<CheckBoxIcon color={x.value === "found" ? "error" : "success"} />)
                                                    : item.measure === "BAD TAB WORDS" ? (<CheckBoxIcon color={x.score === "found" ? "error" : "success"} />)
                                                        : item.measure === "SPAMMY WORDS" ? (<CheckBoxIcon color={x.score === "found" ? "error" : "success"} />) : null}
                    </div>
                )
            })}
        </>
    );
};

export default Checkboxmark;