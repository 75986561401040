import React, { useState, useRef, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Box,
    Container,
    Typography,
    TextField,
    Paper,
    Grid,
    Button,
} from '@mui/material';
import classObj from './MainComponent.module.css'
import AAIC_LOGO from '../../assets/img/aaic.png';
import MARXEED_HEADER_LOGO from '../../assets/img/marxeed-subject-score.png';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux'
import { FETCH_DATA_START } from '../../redux/actionTypes'
import { useSearchParams } from "react-router-dom";

import Score from './Score'
import Cards from './Cards'

const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
});

const MainComponent = (props) => {
    const classes = useStyles();
    const [search, setSearch] = useSearchParams();
    const [searchResult, setSearchResult] = useState(false)
    const [subject, setSubject] = useState('')
    const titleRef = useRef()
    const inputRef = useRef()
    const [errors, setErrors] = useState({});

    const title = search.get('subject');

    const handleChange = (event) => {
        setSearch({ subject: event.target.value });
    };

    const fetchData = () => {
        setErrors(validate(title));
        if (title.length !== 0) {
            setSubject(title)
            setSearchResult(true)
        } else if (title.length === 0) {
            setErrors(validate(title));
            setSearchResult(false)
        }
    }

    const { data } = useSelector(state => state.data)

    // console.log(data);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: FETCH_DATA_START, subject })
    }, [dispatch, subject])

    const gettingData = () => {
        inputRef.current.scrollIntoView({ behavior: 'smooth' })
        setSearch({ subject: "Great design starts with great research!" });
    }

    const validate = (title) => {
        let error = {};
        if (!title) {
            error.title = 'Subject line is required'
        }
        return error;
    };

    // Redirect link
    const hostName = window.location.hostname
    let URL_MARXEED
    let URL_AAIC

    if (hostName === 'subjectspamscore.dev.marxeed.com' || hostName === 'localhost') {
        URL_MARXEED = process.env.REACT_APP_DEV_MARXEED_URL
        URL_AAIC = process.env.REACT_APP_DEV_AAIC_URL
    } else {
        URL_MARXEED = process.env.REACT_APP_PROD_MARXEED_URL
        URL_AAIC = process.env.REACT_APP_PROD_AAIC_URL
    }

    const redirectLink = () => { window.open(URL_MARXEED, '_blank') }
    const redirectLinkAi = () => { window.open(URL_AAIC, '_blank') }
    return (
        <React.Fragment>
            <Paper style={{ textAlign: "center" }}>
                <img
                    id="marxeed_logo"
                    src={MARXEED_HEADER_LOGO}
                    onClick={redirectLink}
                    alt="Marxeed Logo"
                    className={classObj.heading_img}
                    ref={inputRef}
                />
                <Typography variant='h4' gutterBottom className={classObj.heading}>
                    STAND OUT. PROMINENTLY.
                </Typography>
                <Typography variant="h6" gutterBottom className={classObj.subtext}>
                    "Several emails get lost among 100k others because their subject lines don't do them justice."<br />
                    Ready to craft the perfect subject to get yours opened?
                </Typography>
                <Grid container>
                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3}></Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <TextField
                            id="subject"
                            name='subject'
                            placeholder='Enter your email subject line'
                            className={classes.root}
                            variant="outlined"
                            fullWidth
                            value={title}
                            onChange={handleChange}
                            onFocus={() => { setErrors(errors.title.length ? " " : errors.title) }}
                            error={errors.title}
                            helperText={errors.title ? errors.title : ""}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                        <Button variant="contained" size="large" fullWidth className={classObj.testbuttton} onClick={fetchData}>
                            Test Now
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3}></Grid>
                </Grid>
                <Typography nowrap={true} variant="subtitle2" className={classObj.example} sx={{ mt: 3 }}>
                    Not sure what to test? See an <span onClick={gettingData}>Example here</span>
                </Typography>
                {searchResult ? (
                    <div ref={titleRef}>
                        {data?.summary ? (
                            <Container maxWidth="md">
                                <Typography
                                    variant='h4'
                                    className={classObj.input_title}
                                >
                                    <span style={{ color: 'orange' }}>"</span>{subject}<span style={{ color: 'orange' }}>"</span>
                                </Typography>
                                <Score data={data} />
                                <br />
                                {/* cards */}
                                <Cards data={data} />
                                {/* card */}
                            </Container>
                        ) : <CircularProgress sx={{ mt: 2 }} />}
                    </div>
                ) : null}
                <Box sx={{ mt: 3 }}>
                    <Grid container mt={8}>
                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={classObj.logo}>
                            <img
                                id="aaic-logo"
                                src={AAIC_LOGO}
                                onClick={redirectLinkAi}
                                alt="AAIC Logo"
                                height="180"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography variant="h5" className={classObj.subHeading}>
                                Sponsored by AAIC
                            </Typography>
                            <Typography variant="subtitle1" className={classObj.subtitle1}>
                                Don't stay invisible! Testing your subject lines significantly improves lead generation by up to 200%.
                                Time to rise and shine!
                            </Typography>
                            <Typography variant="body1" className={classObj.body1}>
                                <p id="try_free" onClick={redirectLink}>
                                    Try marXeed for free
                                </p>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                    </Grid>
                </Box>
            </Paper >
        </React.Fragment >
    );
};
export default MainComponent;