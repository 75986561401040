import { takeLatest, all, put, fork, call } from 'redux-saga/effects'
import {
    FETCH_DATA_START,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_FAIL
} from './actionTypes'
import { getDatas } from './api'

export function* onLoadDataAsync({ subject }) {
    try {
        // console.log("title", title)
        const response = yield call(getDatas, subject)
        yield put({ type: FETCH_DATA_SUCCESS, payload: response.data })
    } catch (error) {
        yield put({ type: FETCH_DATA_FAIL, payload: error })
    }
}

export function* onLoadData() {
    yield takeLatest(FETCH_DATA_START, onLoadDataAsync)
}

const dataSaga = [fork(onLoadData)]

export default function* rootSaga() {
    yield all([...dataSaga]);
}