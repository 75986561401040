import React from 'react'
import {
    AppBar,
    Typography,
    Grid,
    Stack,
    Box,
    Divider,
} from '@mui/material';
import MARXEED_LOGO from '../../assets/img/new_marxeed_logo.png';
import AAIC_LOGO from '../../assets/img/aaic-white-logo.png'
import classObj from './SecondFooter.module.css'

// icons
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function SecondFooter() {
    // Redirect link
    const hostName = window.location.hostname
    let URL_MARXEED
    let URL_AAIC
    let URL_FEATURES
    let URL_SUPPORT
    let URL_SERVICES

    if (hostName === 'subjectspamscore.dev.marxeed.com' || hostName === 'localhost') {
        URL_MARXEED = process.env.REACT_APP_DEV_MARXEED_URL
        URL_AAIC = process.env.REACT_APP_DEV_AAIC_URL
        URL_FEATURES = process.env.REACT_APP_DEV_FEATURES_URL
        URL_SUPPORT = process.env.REACT_APP_DEV_SUPPORT_URL
        URL_SERVICES = process.env.REACT_APP_DEV_SERVICES_URL
    } else {
        URL_MARXEED = process.env.REACT_APP_PROD_MARXEED_URL
        URL_AAIC = process.env.REACT_APP_PROD_AAIC_URL
        URL_FEATURES = process.env.REACT_APP_PROD_FEATURES_URL
        URL_SUPPORT = process.env.REACT_APP_PROD_SUPPORT_URL
        URL_SERVICES = process.env.REACT_APP_PROD_SERVICES_URL
    }

    const redirectToMarxeed = () => { window.open(URL_MARXEED, '_blank') }
    const redirectToFeature = () => { window.open(URL_FEATURES, '_blank') }
    const redirectToSupport = () => { window.open(URL_SUPPORT, '_blank') }
    const redirectToServices = () => { window.open(URL_SERVICES, '_blank') }
    const redirectLinkAi = () => { window.open(URL_AAIC, '_blank') }

    return (
        <Box>
            <AppBar position="static" color="primary" style={{ backgroundColor: "#1e2228" }}>
                <Grid container my={4}>
                    {/* First Grid */}
                    <Grid item xs={12} sm={0.5} md={0.5} xl={0.5}></Grid>
                    <Grid item xs={12} sm={3} md={3} xl={3}>
                        <div>
                            <img
                                id="redirectToMarxeed"
                                src={MARXEED_LOGO}
                                onClick={redirectToMarxeed}
                                alt="Marxeed Logo" height="45"
                            />
                        </div>
                        <Typography variant="subtitle1" mt={2} className={classObj.sub_title}>
                            Predictable marketing campaign with AI-powered content recommendation
                        </Typography>
                        <div className={classObj.icons}>
                            <a href="https://twitter.com/AppliedAIC" target="_blank" rel="noreferrer" style={{ color: 'white' }}>
                                <TwitterIcon />
                            </a>
                            <a href="https://www.facebook.com/appliedaiconsulting" target="_blank" rel="noreferrer" className={classObj.icons_tag}>
                                <FacebookIcon />
                            </a>
                            <a href="https://in.linkedin.com/company/appliedaiconsulting" target="_blank" rel="noreferrer" className={classObj.icons_tag}>
                                <LinkedInIcon />
                            </a>
                            <a href="https://www.youtube.com/channel/UCGDUXXG1HVxsEe4Xs93ze_Q/featured" target="_blank" rel="noreferrer" className={classObj.icons_tag}>
                                <YouTubeIcon />
                            </a>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1.5} md={1.5} xl={1.5}>
                        <Stack>
                            <Typography variant="h6" id="feature" onClick={redirectToFeature} className={classObj.menu}>
                                Features
                            </Typography>
                            <Typography variant="h6" id="support" onClick={redirectToSupport} className={classObj.menu}>
                                Support
                            </Typography>
                            <Typography variant="h6" id="services" onClick={redirectToServices} className={classObj.menu}>
                                Services
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} xl={3}>
                        <Typography variant="h6" className={classObj.request_demo}>Request A Demo</Typography>
                        <Typography variant="subtitle2" mt={2} className={classObj.demo_subtitle}>
                            Want to contact customer support?<br /> Email our customer champion team at
                        </Typography>
                        <a href='mailto:support@marxeed.com' target="_blank" rel="noreferrer" className={classObj.link}>
                            support@marxeed.com
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} xl={4}>
                        <Typography variant="h6" className={classObj.digital}>
                            Digital Marketing Support or question?
                        </Typography>
                        <Typography pb={4}>
                            <a href='mailto:contact@appliedaiconsulting.com' target="_blank" rel="noreferrer" className={classObj.link}>
                                contact@appliedaiconsulting.com
                            </a>
                        </Typography>
                        <div>
                            <img
                                id="aaic"
                                src={AAIC_LOGO}
                                onClick={redirectLinkAi}
                                alt="Applied AI Logo" height="50"
                            />
                        </div>
                    </Grid>
                    {/* <Grid xs={12} sm={1} md={1} lg={1} xl={1}></Grid> */}
                    {/* second grid */}
                    <Grid item xs={12} sm={0.5} md={0.5} xl={0.5}></Grid>
                    <Grid item xs={12} sm={10.5} md={10.5} xl={10.5} my={4}>
                        <Divider light={true} color="#909090" />
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} xl={1}></Grid>
                    {/* third grid */}
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <Typography className={classObj.footer_text}>
                            © Copyright 2022 AppliedAIConsulting - All Rights Reserved
                        </Typography>
                    </Grid>
                </Grid>
            </AppBar>
        </Box>
    )
}