import * as types from './actionTypes'

const initialState = {
    data: [],
    error: null,
    loading: false,
}

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_DATA_START:
            return {
                ...state,
                loading: true,
            }
        case types.FETCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            }
        case types.FETCH_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
}

export default dataReducer;