import axios from 'axios'

export const getDatas = async (subject) => {
  const url = `${process.env.REACT_APP_API_URL}?subject=${subject}`;
  const key = `${process.env.REACT_APP_API_KEY}`;
  return await axios({
    method: "GET",
    url,
    headers: {
      'Authorization': `Bearer ${key}`
    }
  })
}

