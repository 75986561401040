import React from 'react';
import {
    Typography,
    Grid,
    Card,
    CardHeader,
    CardContent,
} from '@mui/material';
import classObj from './MainComponent.module.css'
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import LinearProgress from "@mui/material/LinearProgress";

const Score = (props) => {
    const { data } = props;
    return (
        <Card>
            <CardHeader
                sx={{ backgroundColor: '#edebeb' }}
                titleTypographyProps={{ variant: 'h5', fontWeight: 'bold', fontFamily: 'Montserrat , sans-serif', color: '#474747' }}
                title="SUBJECT SCORE"
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={3} p={3}>
                        <div id="score" className={classObj.scoreCanvas} >
                            <CircularProgressbar
                                value={data.summary?.score}
                                text={data.summary?.grade}
                                styles={buildStyles({
                                    strokeLinecap: "butt",
                                    pathColor: data.summary?.score >= 80 ? "#388e3c" : data.summary?.score >= 60 && data.summary?.score < 75 ? '#42a5f5' : data.summary?.score >= 40 ? '#f57c00' : data.summary?.score >= 20 ? '#ab47bc' : '#d32f2f',
                                    textColor: data.summary?.score >= 80 ? "#388e3c" : data.summary?.score >= 60 && data.summary?.score < 75 ? '#42a5f5' : data.summary?.score >= 40 ? '#f57c00' : data.summary?.score >= 20 ? '#ab47bc' : '#d32f2f',
                                    textSize: '50px'
                                })}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={9} pt={4} pl={2} >
                        <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat , sans-serif', textAlign: "left", color: '#8a8787' }}>
                            {data.summary?.score} Points
                        </Typography>
                        <div style={{ paddingTop: '4%' }}>
                            <LinearProgress
                                variant="determinate"
                                value={data.summary?.score}
                                color={data.summary?.score >= 80 ? 'success' : data.summary?.score >= 60 && data.summary?.score < 75 ? 'primary' : data.summary?.score >= 40 ? 'warning' : data.summary?.score >= 20 ? 'secondary' : 'error'}
                                style={{
                                    height: 16,
                                    borderRadius: 5,
                                    backgroundColor: '#dbd9d9',
                                }}
                            />
                        </div>
                        <Typography variant='h6' className={classObj.score_desc}>
                            {data.summary?.desc}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Score;