import React from 'react';
import {
    Typography, Grid, Card, CardHeader, CardContent, Stack
} from '@mui/material';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren, } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import classObj from './MainComponent.module.css'
import desktop_view from '../../assets/img/desktop_view.png'
import mobile_view from '../../assets/img/mobile_view.png'

import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import PersonIcon from '@mui/icons-material/Person';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Checkboxmark from './Checkboxmark';
import smiley_1 from '../../assets/img/smiley_2 (1).png'
import smiley_2 from '../../assets/img/smiley_2 (2).png'

const Cards = (props) => {
    const { data } = props;
    return (
        <div>
            {data.details?.map((item, i) => {
                return (
                    <div key={i}>
                        <Card>
                            <CardHeader
                                key={i}
                                sx={{ backgroundColor: '#edebeb' }}
                                titleTypographyProps={{ variant: 'h5', fontWeight: 'bold', fontFamily: 'Montserrat , sans-serif', color: '#474747' }}
                                title={item.measure}
                            />
                            <CardContent>
                                <Grid container>
                                    {item.score[0].unit === 'Desktop Preview' ? (
                                        <>
                                            {item.score.map((y) => {
                                                return (
                                                    <Stack direction="column">
                                                        {y.unit === 'Desktop Preview' ? (
                                                            <div className={classObj.image_1}>
                                                                <img
                                                                    height="200"
                                                                    width="450"
                                                                    src={desktop_view}
                                                                    alt="desktop_view"
                                                                /><p className={classObj.view_title_desktop}>{y.value}</p>
                                                            </div>) : (
                                                            <div className={classObj.image_2}>
                                                                <img
                                                                    height="200"
                                                                    width="260"
                                                                    src={mobile_view}
                                                                    alt="mobile_view"
                                                                /><p className={classObj.view_title_mobile}>{y.value}</p>
                                                            </div>
                                                        )}
                                                        <Typography className={classObj.unit} variant='h6'>
                                                            {y.unit}
                                                        </Typography>
                                                    </Stack>)
                                            })}
                                            <div>
                                                <Typography className={classObj.title_desc} variant='h6' style={{ textWeight: 'bolder' }}>
                                                    {item.title.replace(/subject=/g, '')}
                                                </Typography>
                                                <Typography className={classObj.title_desc} variant='h6'>
                                                    {item.score_details}
                                                </Typography>
                                                <Typography className={classObj.title_desc} variant='h6'>
                                                    {item.desc}
                                                </Typography>
                                            </div>
                                        </>)
                                        : (
                                            <>
                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} p={2}>
                                                    {item.score.map((x, i) => {
                                                        return (
                                                            <div key={i} className={classObj.circle}>
                                                                {item.measure === "EXCLAMATION MARK" ? (
                                                                    <div className={classObj.value}>
                                                                        <CircularProgressbarWithChildren styles={buildStyles({
                                                                            backgroundColor: "#C5C5C5",
                                                                            pathColor: x.value === "found" ? "#d32f2f" : "#388e3c",
                                                                        })}>
                                                                            <PriorityHighIcon color={x.value === "found" ? "error" : "success"} sx={{ fontSize: 90 }} />
                                                                        </CircularProgressbarWithChildren>
                                                                    </div>)
                                                                    : item.measure === "SPELLING ERRORS"
                                                                        ? (<div className={classObj.value}>
                                                                            <CircularProgressbarWithChildren styles={buildStyles({
                                                                                backgroundColor: "#C5C5C5",
                                                                                pathColor: x.value === "found" ? "#d32f2f" : "#388e3c",
                                                                            })}>
                                                                                <SpellcheckIcon color={x.value === "found" ? "error" : "success"} sx={{ fontSize: 90 }} />
                                                                            </CircularProgressbarWithChildren>
                                                                        </div>)
                                                                        : item.measure === "PUNCTUATION"
                                                                            ? (<div className={classObj.value}>
                                                                                <CircularProgressbar
                                                                                    value=',;'
                                                                                    text=',;'
                                                                                    styles={buildStyles({
                                                                                        strokeLinecap: "butt",
                                                                                        pathColor: x.value === "found" ? "#d32f2f" : "#388e3c",
                                                                                        textColor: x.value === "found" ? "#d32f2f" : "#388e3c",
                                                                                        textSize: '60px'
                                                                                    })}
                                                                                />
                                                                            </div>)
                                                                            : item.measure === "READABILITY" ? (
                                                                                <div className={classObj.value}>
                                                                                    <CircularProgressbar
                                                                                        maxValue={16}
                                                                                        value={x.value}
                                                                                        text={x.value}
                                                                                        styles={buildStyles({
                                                                                            strokeLinecap: "butt",
                                                                                            pathColor: x.value >= 10 ? '#d32f2f' : x.value >= 8 ? '#f57c00' : '#388e3c',
                                                                                            textColor: x.value >= 10 ? '#d32f2f' : x.value >= 8 ? '#f57c00' : '#388e3c',
                                                                                            textSize: '50px'
                                                                                        })} />
                                                                                </div>)
                                                                                : item.measure === "LENGTH" ? (
                                                                                    <div className={classObj.value}>
                                                                                        {x.unit === 'Characters'
                                                                                            ? (<CircularProgressbar
                                                                                                maxValue={100}
                                                                                                value={x.value}
                                                                                                text={x.value}
                                                                                                styles={buildStyles({
                                                                                                    strokeLinecap: "butt",
                                                                                                    pathColor: x.value < 60 ? '#388e3c' : x.value < 65 ? '#F57C00' : '#d32f2f',
                                                                                                    textColor: x.value < 60 ? '#388e3c' : x.value < 65 ? '#F57C00' : '#d32f2f',
                                                                                                    textSize: '50px'
                                                                                                })} />)
                                                                                            : (
                                                                                                <CircularProgressbar
                                                                                                    maxValue={16}
                                                                                                    value={x.value}
                                                                                                    text={x.value}
                                                                                                    styles={buildStyles({
                                                                                                        strokeLinecap: "butt",
                                                                                                        pathColor: x.value > 4 && x.value < 9 ? '#388e3c' : x.value < 3 ? '#F57C00' : '#d32f2f',
                                                                                                        textColor: x.value > 4 && x.value < 9 ? '#388e3c' : x.value < 3 ? '#F57C00' : '#d32f2f',
                                                                                                        textSize: '50px'
                                                                                                    })} />
                                                                                            )}
                                                                                    </div>)
                                                                                    : item.measure === "SENTIMENT"
                                                                                        ? (<div className={classObj.value}>
                                                                                            <CircularProgressbar
                                                                                                value={x.value === '-' ? '-ve' : x.value === '0.0' ? 'N' : '+ve'}
                                                                                                text={x.value === '-' ? '-ve' : x.value === '0.0' ? 'N' : '+ve'}
                                                                                                styles={buildStyles({
                                                                                                    strokeLinecap: "butt",
                                                                                                    pathColor: x.value === "-" ? "#d32f2f" : x.value === '0.0' ? "#808080" : "#388e3c",
                                                                                                    textColor: x.value === "-" ? "#d32f2f" : x.value === '0.0' ? "#808080" : "#388e3c",
                                                                                                    textSize: '25px'
                                                                                                })}
                                                                                            />
                                                                                        </div>)
                                                                                        : item.measure === 'RE: OR FWD' ? (
                                                                                            <div className={classObj.value}>
                                                                                                <CircularProgressbar
                                                                                                    value='RE:'
                                                                                                    text='RE:'
                                                                                                    styles={buildStyles({
                                                                                                        strokeLinecap: "butt",
                                                                                                        pathColor: x.value === "found" ? "#d32f2f" : "#388e3c",
                                                                                                        textColor: x.value === "found" ? "#d32f2f" : "#388e3c",
                                                                                                        textSize: '25px'
                                                                                                    })} />
                                                                                            </div>)
                                                                                            : item.measure === "FREE" ? (
                                                                                                <div className={classObj.value}>
                                                                                                    <CircularProgressbar
                                                                                                        value='FREE'
                                                                                                        text='FREE'
                                                                                                        styles={buildStyles({
                                                                                                            strokeLinecap: "butt",
                                                                                                            pathColor: x.value === "found" ? "#d32f2f" : "#388e3c",
                                                                                                            textColor: x.value === "found" ? "#d32f2f" : "#388e3c",
                                                                                                            textSize: '25px'
                                                                                                        })} />
                                                                                                </div>)
                                                                                                : item.measure === "PERSONALIZATION" ? (
                                                                                                    <div className={classObj.value}>
                                                                                                        <CircularProgressbarWithChildren styles={buildStyles({
                                                                                                            backgroundColor: "#C5C5C5",
                                                                                                            pathColor: x.value === "found" ? "#388e3c" : "#808080",
                                                                                                        })}>
                                                                                                            <PersonIcon color={x.value === "found" ? "success" : "#808080"} sx={{ fontSize: 90 }} />
                                                                                                        </CircularProgressbarWithChildren>
                                                                                                    </div>)
                                                                                                    : item.measure === "QUESTION IN SUBJECT" ? (
                                                                                                        <div className={classObj.value}>
                                                                                                            <CircularProgressbarWithChildren styles={buildStyles({
                                                                                                                backgroundColor: "#C5C5C5",
                                                                                                                pathColor: x.value === "found" ? "#388e3c" : "#808080",
                                                                                                            })}>
                                                                                                                <CircularProgressbar
                                                                                                                    value='?'
                                                                                                                    text='?'
                                                                                                                    styles={buildStyles({
                                                                                                                        strokeLinecap: "butt",
                                                                                                                        pathColor: x.value === "found" ? "#388e3c" : "#808080",
                                                                                                                        textColor: x.value === "found" ? "#388e3c" : "#808080",
                                                                                                                        textSize: '70px',
                                                                                                                        paddingTop: '2em'
                                                                                                                    })} />
                                                                                                            </CircularProgressbarWithChildren>
                                                                                                        </div>)
                                                                                                        : item.measure === "ALL LOWER CASE" ? (
                                                                                                            <div className={classObj.value}>
                                                                                                                <CircularProgressbar
                                                                                                                    value='a'
                                                                                                                    text='a'
                                                                                                                    styles={buildStyles({
                                                                                                                        strokeLinecap: "butt",
                                                                                                                        pathColor: x.value === "found" ? "#388e3c" : "#808080",
                                                                                                                        textColor: x.value === "found" ? "#388e3c" : "#808080",
                                                                                                                        textSize: '50px'
                                                                                                                    })} />
                                                                                                            </div>)
                                                                                                            : item.measure === "ALL CAPS WORDS" ? (
                                                                                                                <div className={classObj.value}>
                                                                                                                    <CircularProgressbarWithChildren styles={buildStyles({
                                                                                                                        backgroundColor: "#C5C5C5",
                                                                                                                        pathColor: x.value === "found" ? "#d32f2f" : "#388e3c",
                                                                                                                    })}>
                                                                                                                        <TextFieldsIcon color={x.value === "found" ? "error" : "success"} sx={{ fontSize: 80 }} />
                                                                                                                    </CircularProgressbarWithChildren>
                                                                                                                </div>)
                                                                                                                : item.measure === "BAD TAB WORDS" ? (
                                                                                                                    <div className={classObj.value}>
                                                                                                                        <CircularProgressbarWithChildren styles={buildStyles({
                                                                                                                            backgroundColor: "#C5C5C5",
                                                                                                                            pathColor: x.score === "found" ? "#d32f2f" : "#388e3c",
                                                                                                                        })}>
                                                                                                                            <FolderOpenIcon color={x.score === "found" ? "error" : "success"} sx={{ fontSize: 80 }} />
                                                                                                                        </CircularProgressbarWithChildren>
                                                                                                                    </div>)
                                                                                                                    : item.measure === "SPAMMY WORDS" ? (
                                                                                                                        <div className={classObj.value}>
                                                                                                                            <CircularProgressbarWithChildren styles={buildStyles({
                                                                                                                                backgroundColor: "#C5C5C5",
                                                                                                                                pathColor: x.score === "found" ? "#d32f2f" : "#388e3c",
                                                                                                                                textColor: "#C5C5C5",
                                                                                                                            })}>
                                                                                                                                <ReportGmailerrorredIcon color={x.score === "found" ? "error" : "success"} sx={{ fontSize: 80 }} />
                                                                                                                            </CircularProgressbarWithChildren>
                                                                                                                        </div>)
                                                                                                                        : item.measure === "EMOJI" ? (
                                                                                                                            <div>
                                                                                                                                <img
                                                                                                                                    height="120"
                                                                                                                                    src={smiley_1}
                                                                                                                                    alt="desktop_view"
                                                                                                                                    className={classObj.emoji}
                                                                                                                                />
                                                                                                                                <img
                                                                                                                                    height="120"
                                                                                                                                    src={smiley_2}
                                                                                                                                    alt="desktop_view"
                                                                                                                                    className={classObj.emoji}
                                                                                                                                />
                                                                                                                            </div>) : x.value
                                                                }
                                                                <Typography className={classObj.unit} variant='h6'>
                                                                    {x.unit === 'sentiment' ? null : x.unit}
                                                                </Typography>
                                                            </div>
                                                        )
                                                    })}
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                    <Typography className={classObj.title_desc} variant='h6' style={{ textWeight: 'bolder', display: 'flex' }}>
                                                        <Checkboxmark item={item} /><span>{item.title}</span>
                                                    </Typography>
                                                    <Typography className={classObj.title_desc} variant='h6'>
                                                        {item.score_details}
                                                    </Typography>
                                                    <Typography className={classObj.title_desc} variant='h6'>
                                                        {item.desc}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                </Grid>
                            </CardContent>
                        </Card><br />
                    </div>
                )
            })}
        </div>
    );
};

export default Cards;